import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image/index';

import { Container, responsiveDevices, Title, Text } from '../../../../Misc/Styled/Styled';

export const HomeHeroSectionStyled = styled(BackgroundImage)`
  height: 100vh;
  position: relative;
  padding-top: ${9.5 + 6.3}rem;
  ${responsiveDevices.laptop} {
    height: auto;
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  ${Container} {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--container-padding-double);
  }
`;

export const TitleWrapper = styled.hgroup`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${Title} {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }
`;

export const HeroCtaBox = styled.div`
  box-shadow: var(--box-shadow);
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: var(--bd-filter-blur);
  padding: 1.9rem 2.8rem;
  border-radius: var(--border-radius);
  margin-top: 6.3rem;
  ${responsiveDevices.mobileS} {
    padding: 1.9rem 1rem;
  }
  > ${Title} {
    margin-bottom: 1.9rem;
  }
`;

export const HeroCtaBoxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeroCtaPhones = styled.div`
  display: flex;
  flex-direction: column;
  > ${Text} {
    margin-bottom: 0.6rem;
  }
`;

export const HeroCtaIcon = styled(Image)`
  overflow: visible !important;
  margin-right: 1.3rem;
  user-select: none;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6.3rem;
  height: 100%;
  ${responsiveDevices.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const ContentColumn = styled.div`
  height: 100%;
`;

export const HeroImagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 10vh;
  z-index: -1;
  ${responsiveDevices.laptop} {
    display: none;
  }
`;

export const HomeHeroChickenImage = styled(Image)`
  overflow: visible !important;
  position: absolute !important;
  max-width: 315px;
  width: 100%;
  bottom: 0;
  left: 15%;
  user-select: none;
  :after {
    content: '';
    width: 100%;
    height: 20px;
    position: absolute;
    z-index: -1;
    display: inline-flex;
    left: 10%;
    bottom: 1.4%;
    transform: rotate(-1.5deg);
    
    border-radius: 100%;
    background-color: var(--c-black);
    opacity: 0.4;
    filter: blur(7px);
  }
`;

export const HomeHeroBasketWithEggsImage = styled(Image)`
  position: absolute !important;
  overflow: visible !important;
  max-width: 300px;
  width: 100%;
  bottom: -10%;
  left: 67%;
  user-select: none;
  :after {
    content: '';
    width: 100%;
    height: 20px;
    position: absolute;
    z-index: -1;
    display: inline-flex;
    left: 4%;
    bottom: 12%;
    transform: rotate(-1.5deg);
    
    border-radius: 100%;
    background-color: var(--c-black);
    opacity: 0.4;
    filter: blur(7px);
  }
`;
