import React from 'react';

import { useSiteMetadata } from '../services/Queries/DataQueries';
import { useImages } from '../services/Queries/ImageQueries';

import MainLayout from '../components/Misc/Layout/MainLayout';
import HomePage from '../components/Pages/HomePage/HomePage';

const HomePageSr = () => {
  const siteMetadata = useSiteMetadata();
  const { openGraphImage } = useImages();

  return (
    <MainLayout
      meta={{ pageTitle: 'Добродошли', ...siteMetadata, openGraphImage: openGraphImage.childImageSharp.fixed.src }}
      mainNav={siteMetadata.mainNav}
    >
      <HomePage />
    </MainLayout>
  );
}

export default HomePageSr;


