import React from 'react';

import { useImages } from '../../../../../services/Queries/ImageQueries';

import { Container, Text, Title } from '../../../../Misc/Styled/Styled';
import {
  ContentWrapper, AboutUsSectionStyled, AboutUsGridImage,
} from './AboutUsStyled';

const AboutUsSection = ({ sectionId }) => {
  const { eggsOnTheTable, eggsInCarton } = useImages();
  return (
    <AboutUsSectionStyled id={sectionId}>
      <Container>
        <Title as={'h3'} fontWeight={'bold'} marginBottom={6.3} withBorder>О НАМА</Title>
        <ContentWrapper>
            <AboutUsGridImage fluid={eggsInCarton.childImageSharp.fluid} />
            <Text>
              Пресија се бави производњом и продајом свежих конзумних јаја од 2000. године.
              <br />
              <br />
              Фирма се налази у Иланџи, Општина Алибунар.
              Канцеларије су у Панчеву на адреси Ослобођења 5.
            </Text>
            <Text>
              Вршимо доставу на територији општине Панчево и општине Београд.
              <br />
              <br />
              Храна је из сопствене мешаоне по HACCP стандарду.
            </Text>
            <AboutUsGridImage fluid={eggsOnTheTable.childImageSharp.fluid} />
        </ContentWrapper>
      </Container>
    </AboutUsSectionStyled>
  );
}

export default AboutUsSection;
