import React from 'react';

import HomeHeroSection from './Sections/Hero/Hero';
import AboutUs from './Sections/AboutUs/AboutUs';
import ProductsSection from './Sections/Products/Products';

const HomePage = ({}) => (
  <>
    <HomeHeroSection  />
    <AboutUs sectionId={'o-nama'} />
    <ProductsSection sectionId={'proizvodi'}/>
  </>
);

export default HomePage;
