import React from 'react';

import { useImages } from '../../../../../services/Queries/ImageQueries';

import { Container, Text, Title } from '../../../../Misc/Styled/Styled';
import {
  TitleWrapper, ContentWrapper, ContentColumn, HomeHeroSectionStyled, HeroImagesWrapper, HomeHeroChickenImage,
  HomeHeroBasketWithEggsImage, HeroCtaBox, HeroCtaBoxContent, HeroCtaPhones, HeroCtaIcon
} from './HomeHeroStyled';

const HomeHeroSection = () => {
  const { grassFieldBg, chicken, basketWithEggs, telephoneNegativeIcon } = useImages();
  return (
    <HomeHeroSectionStyled
      Tag={'section'}
      fluid={grassFieldBg.childImageSharp.fluid}
    >
      <Container>
        <TitleWrapper>
          <Title blank>
            <Title as={'span'} alike={'h3'} fontWeight={'bold'}>ПРОИЗВОДЊА И ПРОДАЈА</Title>
            <Title as={'span'} alike={'h2'} fontWeight={'bold'}>СВЕЖИХ КОНЗУМНИХ  ЈАЈА</Title>
          </Title>
        </TitleWrapper>
        <ContentWrapper>
          <ContentColumn>
            <HeroImagesWrapper>
              <HomeHeroChickenImage fluid={chicken.childImageSharp.fluid} />
              <HomeHeroBasketWithEggsImage fluid={basketWithEggs.childImageSharp.fluid} />
            </HeroImagesWrapper>
          </ContentColumn>
          <ContentColumn>
            <HeroCtaBox>
              <Title as={'h3'} alike={'h4'}>ЗА ВИШЕ ИНФОРМАЦИЈА <br /> КОНТАКТИРАЈТЕ НАС:</Title>
              <HeroCtaBoxContent>
                <HeroCtaIcon fixed={telephoneNegativeIcon.childImageSharp.fixed} />
                <HeroCtaPhones>
                  <Text as={'a'}
                        href={'tel:+3811334722'}
                        color={'--c-blue-d'}
                        fontWeight={'bold'}
                        fontSize={2.4}
                        lineHeight={2.8}
                  >
                    +381 13 334 722
                  </Text>
                  <Text as={'a'}
                        href={'tel:+38162707404'}
                        color={'--c-blue-d'}
                        fontWeight={'bold'}
                        fontSize={2.4}
                        lineHeight={2.8}
                  >
                    +381 62 707 404
                  </Text>
                </HeroCtaPhones>
              </HeroCtaBoxContent>
            </HeroCtaBox>
          </ContentColumn>
        </ContentWrapper>
      </Container>
    </HomeHeroSectionStyled>
  );
}

export default HomeHeroSection;
