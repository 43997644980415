import React from 'react';

import { useImages } from '../../../../../services/Queries/ImageQueries';

import { Container, Text, Title } from '../../../../Misc/Styled/Styled';
import {
  Table, AboutUsSectionStyled, TableRow,
} from './ProductsStyled';

const ProductsSection = ({ sectionId }) => {
  const { grassBg } = useImages();
  return (
    <AboutUsSectionStyled
      Tag={'section'}
      fluid={grassBg.childImageSharp.fluid}
      id={sectionId}
    >
      <Container>
        <Title as={'h3'} fontWeight={'bold'} marginBottom={6.3} withBorder>ПРОИЗВОДИ</Title>
        <Table>
          <TableRow>
            <Text fontWeight={'bold'}>L класа</Text>
            <Text>63-73г</Text>
            <Text>360 ком/пак</Text>
            <Text>30/1 <br /> 10/1</Text>
          </TableRow>
          <TableRow>
            <Text fontWeight={'bold'}>M класа</Text>
            <Text>53-63г</Text>
            <Text>360 ком/пак</Text>
            <Text>30/1 <br /> 10/1</Text>
          </TableRow>
          <TableRow>
            <Text fontWeight={'bold'}>XL класа</Text>
            <Text>74г</Text>
            <Text>200 ком/пак</Text>
            <Text>/</Text>
          </TableRow>
          <TableRow>
            <Text fontWeight={'bold'}>S класа</Text>
            <Text>43-53г</Text>
            <Text>360 ком/пак</Text>
            <Text>/</Text>
          </TableRow>
        </Table>
      </Container>
    </AboutUsSectionStyled>
  );
}

export default ProductsSection;
