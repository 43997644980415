import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image/index';

import { Container, responsiveDevices, Text } from '../../../../Misc/Styled/Styled';

export const AboutUsSectionStyled = styled(BackgroundImage)`
  position: relative;
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
  ${Container} {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--container-padding-double);
  }
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  height: 6.3rem;
  ${Text} {
    flex: 0 0 25%;
  }
  ${responsiveDevices.mobileM} {
    flex-direction: column;
    justify-content: center;
    padding: 1.3rem 0;
    height: auto;
    ${Text} {
      flex: 0 0 auto;
      margin-bottom: 0.9rem;
      &:first-of-type {
        margin-bottom: 1.3rem;
      }
    }
  }
`;

export const Table = styled.div`  
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgba(208, 234, 230, 0.7);
  border-radius: var(--border-radius);
  backdrop-filter: var(--bd-filter-blur);
  box-shadow: var(--box-shadow);
  ${TableRow} {
    &:not(:last-of-type) {
     border-bottom: 0.3rem solid var(--c-white);
    }
  }
`;


