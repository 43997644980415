import styled from 'styled-components';
import Image from 'gatsby-image';

import { Container, Text, responsiveDevices } from '../../../../Misc/Styled/Styled';

export const AboutUsSectionStyled = styled.section`
  position: relative;
  background: var(--lg-white-acc-blue);
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
  ${Container} {
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--container-padding-double);
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6.3rem;
  grid-row-gap: 4.8rem;
  align-items: center;
  ${responsiveDevices.laptop} {
    grid-template-columns: repeat(1, 1fr);
    ${Text} {
      &:first-of-type {
        order: -1;
      }
    }
  }
`;

export const AboutUsGridImage = styled(Image)`
  width: 100%;
  user-select: none;
`;
